import Contact from "./Contact";
import video from '../assets/img/header-video.mp4';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import aboutImg from '../assets/img/about-img1.jpg';
import { BiBuildingHouse,BiGlobe,BiDonateHeart } from "react-icons/bi";

function Home() {
  const navigate = useNavigate();
  const [searchItem, setSerachItem] = useState('');

  const inputHandleChange = (event) => {
    setSerachItem(event.target.value);
  };

  const searchButtonHandle = () => {
    if(searchItem){
      navigate(`/properties/search=${searchItem}`);
    }
  };

  return (
    <>
      <section id="heroSection">
        <div class="overlay"></div>
        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
          <source src={video} type="video/mp4" />
        </video>

        <div className="headding-div">
          <h1>Find Your Best Property </h1>
          <p className='p-3 text-center text-white'>Our Real Estate agency has a wide variety of services we cen offer. Everything<br />
            connected with buying or selling any type of property is our expertise. Your Best Property </p>

          <div className="main-search-input-wrap">
            <div className="main-search-input fl-wrap">
              <div className="main-search-input-item">
                <input type="text" placeholder="Search Property..."  onChange={inputHandleChange}/>
              </div>
              <button className="main-search-button" onClick={searchButtonHandle}>Search</button>
            </div>
          </div>
        </div>
      </section>

      <section id="About" className="section-bg" style={{paddingBottom: '0px'}}>
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h3>About Us</h3>
          </div>
          <div className="row pb-3">
            <div className="col-lg-6 text-center" data-aos="fade-right" data-aos-delay="100">
              <img src={aboutImg} className="img-fluid" alt="" style={{width:'95%'}} />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <h2 className='montserrat-semibold'>Welcome To Lakshmi Developers</h2>
             <p className="pt-2 text-justify">
                we understand that people everywhere are searching for a home to call their own.
                A home is a cherished memory that lasts forever, where the walls embrace memories, 
                the ceilings shelter love and laughter, where the quiet corners offer a much-needed 
                pause and life itself becomes a reason to celebrate.<br/><br/>

                We want to make the journey as joyful as the moment when you finally find the perfect home.
                So we begin by partnering with our customers from the start and being there when it matters 
                the most - right from online search to brokers to home loans to paperwork to finally finding that perfect home.
                At makaan.com, we help you find joy.<br/><br/>

                To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a home, 
                and digitally enabling them throughout their journey. We do that with data, design, technology, and above all,
                the passion of our people while delivering value to our shareholders.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid mt-3 common-bg-img">
          <div className='row pt-5 pb-5 d-flex justify-content-center'>
            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
                <div className='icon'><BiBuildingHouse/></div>   
                  <div class="card-body">
                    <h5 class="montserrat-semibold card-title">Our Mission</h5>
                    <p class="card-text">To provide superior full-service real estate brokerage with in-depth market knowledge and ethical practices.</p>
                  </div>
              </div>
            </div>

            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
              <div className='icon'><BiGlobe/></div>   
                  <div class="card-body">
                    <h5 class="montserrat-semibold card-title">Our Vission</h5>
                    <p class="card-text">Be recognized as the leading agency in property services as wall as a reliable 
                    source of market information and advice.</p>
                  </div>
              </div>
            </div>

            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
                <div className='icon'><BiDonateHeart/></div>  
                  <div class="card-body">
                    <h5 class="montserrat-semibold card-title">Our value</h5>
                    <p class="card-text">Building long-term relationships with clients,staying as loyal to our employee as they are to us,constant growth and development.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <Contact />

    </>
  )
}

export default Home;

