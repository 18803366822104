import { FaMapMarkerAlt, FaChartArea, FaShareSquare, } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube'
import { Modal, Button } from "react-bootstrap";
import PropertyApi from "../api/PropertyApi";
import PubSub from 'pubsub-js';
import Form from "react-bootstrap/Form";

function PropertyDetails() {
    const { state } = useLocation();
    const [show, setShow] = useState(false);
    const [body, setBody] = useState({});
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (!window.myMap) {
            window.myMap = myMap;
            const googleMapScript = document.createElement("script");
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBZsH0US1O6fSogoqBTUpUkvEWqs-rYMlY&callback=myMap`;
            googleMapScript.async = true;
            window.document.body.appendChild(googleMapScript);

        } else {
            myMap();
        }
    }, []);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        setValidated(false);
        setBody({})
    }

    // this code use of Format Currency start here
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'INR',
        }).format(number);
    }

    const handleChange = (e) => {
        setBody({ ...body, "propertyid": state.id, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        console.log("====handle submit====", body);
        e.preventDefault();
    
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault(); e.stopPropagation();
        } else {
            setValidated(true);
        }

        let result = await PropertyApi.interestedPropertyCreateLead(body, "ibs_laxmi");
        if (result.id) {
            PubSub.publish("RECORD_SAVED_TOAST", {
                title: "Record Insert",
                message: "Your Record Successfully Insert",
            });
            setShow(false);
        }else {
            PubSub.publish("RECORD_ERROR_TOAST", {
                title: "Record Failed",
                message: "Record Failed",
            });
        }   

    };

    // this code use of shwoing property lcoation map
    const myMap = () => {
        let contentString = `<h4>${state.name}</h4>
        <label className='maplabel'>Address:</label> <p>${state.street},${state.city},${state.state},${state.pincode},${state.country}</p>`;
        const infowindow = new window.google.maps.InfoWindow({
            content: contentString,
            ariaLabel: "Uluru",
        });

        var mapProp = {
            center: new window.google.maps.LatLng(51.508742, -0.12085),
            zoom: 14,
        };
        var map = new window.google.maps.Map(
            document.getElementById("googleMap"),
            mapProp
        );

        var geocoder = new window.google.maps.Geocoder();
        var address = `${state.street},${state.city},${state.state},${state.pincode},${state.country}`;
        geocoder.geocode({ address: address }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                var marker = new window.google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    title: `${state.name}`,
                });

                marker.addListener("click", () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                    });
                });
            }
        });
    };


    return (
        <>
            <section id="propertyDetails" className="section-bg">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-lg-12 p-3">
                            <h3 className="montserrat-semibold">Property Details</h3>
                        </div>
                        <div className="col-lg-12">
                            <div className="card p-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-8 pb-2">
                                            <h6 className="montserrat-semibold">{state.type}</h6>
                                            <p><FaMapMarkerAlt className="theme-color1" size={15} /> {state.street} {state.city} {state.pincode} {state.state} {state.country}</p>
                                        </div>

                                        <div className="offset-lg-1 col-lg-3 pb-1">
                                            <div className="float-end">
                                                <button className="btn small-btn p-2 mb-2" type="button" onClick={handleShow}>Interested</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-5">

                                            {/* This code use of property image carousel start here */}
                                            <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                                                <div className="carousel-inner">
                                                    {state?.files ? (state?.files?.map((value, index) => (
                                                        <div className={`carousel-item ${index === 0 ? "active" : ""}`} key={index}>
                                                            <img src={`https://property.indicrm.io/images/ibs_laxmi/${state.id}/${value.Id}.${value.fileType}`}
                                                                className="d-block img-fluid w-100" alt="property img" />
                                                        </div>))) :
                                                        <div className={`carousel-item active`}>
                                                            <img src="/no-img-found.svg" class="card-img-top w-100 img-fluid" alt="noImage" />
                                                        </div>}

                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>

                                                <div class="carousel-indicators">
                                                    {state?.files ? (state?.files?.map((value, index) => (
                                                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={`${index === 0 ? "active" : ""}`}
                                                            aria-current="true" aria-label="Slide 1">
                                                            <img src={`https://property.indicrm.io/images/ibs_laxmi/${state.id}/${value.Id}.${value.fileType}`} className="d-block img-fluid w-100" alt="..." />
                                                        </button>
                                                    ))) : <button type="button" data-bs-target="#carouselExampleCaptions" className="active"
                                                        aria-current="true" aria-label="Slide 1">
                                                        <img src="/no-img-found.svg" class="card-img-top w-100 img-fluid" alt="noImage" />
                                                    </button>}
                                                </div>
                                            </div>
                                            {/* End code of property image carousel */}

                                            <div className="col-lg-12 mt-5 pt-4">
                                                <div
                                                    id="googleMap"
                                                    style={{ width: "100%", height: "200px", top: '20px' }}
                                                ></div>
                                            </div>

                                        </div>

                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <h5>{state.name}</h5>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="float-lg-end">
                                                        <h5 className='property-price montserrat-semibold theme-color1'>{formatCurrency(state.cost)}</h5>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mt-3 mb-3">
                                                    <span class="badge rounded-pill  me-2 ps-3 pt-2 pb-2 pe-3 montserrat-regular" style={{ fontSize: '16px', backgroundColor: '#ef814b' }}>{state.legalstatus}</span>
                                                    <span class="badge rounded-pill  e-2 ps-3 pt-2 pb-2 pe-3 montserrat-regular" style={{ fontSize: '16px', backgroundColor: '#91bfb2' }}>{state.propertyfor}</span>
                                                </div>

                                                <div className="col-lg-12 mt-2 mb-3 d-flex">
                                                    <div className="d-flex">
                                                        <span><FaChartArea className="theme-color1" size={18} /></span>
                                                        <h6 className="mt-1 ms-2 pe-3">Area: {state.area}</h6>
                                                    </div>

                                                    <div className="d-flex">
                                                        <span><FaShareSquare className="theme-color1" size={18} /></span>
                                                        <h6 className="mt-1 ms-2">Areameasure: {state.areameasure}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <p>{state.description}</p>
                                            <div className="col-lg-12">
                                                <ReactPlayer url={state.vidurl} width="100%" className="mt-4" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card p-4">
                        <div className="row">
                            <div className="col-lg-12">
                                <h5 className="card-title" >Agent details</h5>
                                <div className="row">
                                    <div className="col-lg-4">
                                        <h6 className="montserrat-semibold pt-3">Agent Name</h6>
                                        <h6>{state.ownername}</h6>
                                    </div>

                                    <div className="col-lg-4">
                                        <h6 className="montserrat-semibold pt-3">Agent Email</h6>
                                        <h6>{state.owneremail}</h6>
                                    </div>

                                    <div className="col-lg-4">
                                        <h6 className="montserrat-semibold pt-3">Agent Contact</h6>
                                        <h6>{state.ownerphone}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* this code use of Open Model Start here */}

                <Modal show={show} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title><h4 className="modal-title">Contact Infomation</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit}  noValidate validated={validated}>
                            <Form.Group controlId="formBasicStreet" className="pt-3">
                                <Form.Label className="form-view-label" htmlFor="formBasicStreet">
                                    First Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstname"
                                    placeholder="Enter First Name"
                                    value={body.firstname}
                                    onChange={handleChange}  required/>
                                <Form.Control.Feedback type="invalid">
                                    Please Enter First Name
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicStreet" className="pt-3">
                                <Form.Label className="form-view-label" htmlFor="formBasicStreet">
                                    Last Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastname"
                                    placeholder="Enter First Name"
                                    value={body.lastname}
                                    onChange={handleChange} required />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Last Name
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicStreet" className="pt-3">
                                <Form.Label className="form-view-label" htmlFor="formBasicStreet">
                                    Email
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Enter Your Email"
                                    value={body.email}
                                    onChange={handleChange} required />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Email
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicStreet" className="pt-3">
                                <Form.Label className="form-view-label" htmlFor="formBasicStreet">
                                    Phone
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    pattern="[0-9]*"
                                    name="phone"
                                    placeholder="Enter Your Email"
                                    value={body.phone}
                                    onChange={handleChange} required />
                                <Form.Control.Feedback type="invalid">
                                    Please Enter Phone Number
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn small-btn" type="button" onClick={handleSubmit} >Submit</Button>
                        <Button variant="outlined" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* end code of Open Model */}
            </section>

        </>
    )
}

export default PropertyDetails;
