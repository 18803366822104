import { NavLink } from 'react-router-dom';
import logo from '../assets/img/logo1.jpg';
import React, { useState } from 'react';

function Navbar() {

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  
  return (
    <>

      <header id="Header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <img src={logo} width={120} alt="logo-img"/>
            <button class="custom-toggler navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link">Home</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/properties" className="nav-link">Properties</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/projects" className="nav-link">Projects</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about" className="nav-link">About</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link">Contact</NavLink>
                </li>
                
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar;
