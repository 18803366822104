import { FaTwitter, FaFacebookF, FaInstagram, FaLinkedin, FaSkype } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Footer() {

    // this code use of page scrolling start here
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <>
            <footer id="footer">
                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 footer-contact">
                                <h3 style={{ color: '#fff' }}>Office Address<span>.</span></h3>
                                <p>
                                    Sr. No. 15, Ambegaon Budruk,<br /> Pune-411046, Maharashtra,<br /> INDIA.<br /><br />
                                    <strong>Phone: </strong>+91 94140 07736<br />
                                    <strong>Email: </strong>shilpi.b@ibirdsservices.com<br />
                                </p>
                            </div>

                            <div class="col-lg-4 col-md-4 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/properties">Properties</NavLink></li>
                                    <li><NavLink to="/projects">Projects</NavLink></li>
                                    <li><NavLink to="/about">About Us</NavLink></li>
                                    <li><NavLink to="/contact">Contact US</NavLink></li>
                                </ul>
                            </div>


                            <div class="col-lg-4 col-md-4 footer-links">
                                <h4>Social Media</h4>
                                <div class="social-links mt-3">
                                    <NavLink to="https://www.facebook.com/people/Indicrm_ibirds/100092753678632/"  target="_blank" class="facebook"><FaFacebookF style={{ color: '#5FAF8A' }} /></NavLink>
                                    <NavLink to="https://instagram.com/indicrmibirds?igshid=MzRlODBiNWFlZA==" class="instagram" target="_blank"><FaInstagram style={{ color: '#5FAF8A' }} /></NavLink>
                                    <NavLink to="https://www.linkedin.com/showcase/indicrm/?viewAsMember=true" class="google-plus" target="_blank"><FaLinkedin style={{ color: '#5FAF8A' }} /></NavLink>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container py-4">
                    <div className="copyright">
                        &copy; Copyright <strong><span>iBirds Services</span></strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
