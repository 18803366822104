
import { FaMapMarkerAlt, } from "react-icons/fa";
import PropertyApi from "../api/PropertyApi";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'


function Projects() {
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    // This Function use of fetch Project API
    const fetchData = async () => {
        let project = await PropertyApi.fetchProjectByTenantCode("ibs_laxmi");

        if (project && project?.length > 0) {
            let projectArray = [];
            project.forEach((element) => {
                if (element?.files) {
                    let files = `[${element.files.replaceAll("@#", ",")}]`;
                    element.files = JSON.parse(files);
                }
                projectArray.push(element);
            });
            console.log("projectArray --> ", projectArray);
            setItems(projectArray);
        } else {
            setItems([]);
        }
    };

    return (
        <>
            <section id="Project">
                <div className="container">
                    <div className="row">
                        {items ? items.map((curElement) => (
                            <div className="col-lg-3 mb-4">
                            <Link to={`/projectProperty1/`} state={curElement}>
                                <div className="card mb-3 p-3 h-100">
                                    {curElement?.files?.length > 0  ?  
                                        <img src={`https://property.indicrm.io/images/ibs_laxmi/${curElement.id}/${curElement.files[0].Id}.${curElement.files[0].fileType}`} class="card-img-top" alt='Project img' /> : 
                                        <img src="/no-img-found.svg" class="card-img-top w-100 img-fluid" alt="noImage" />
                                    }
                                    <div className="card-body">
                                        <h6 className="card-title">{curElement.name}</h6>
                                        <p className="card-text"><FaMapMarkerAlt className="theme-color1" size={15} /> {curElement.street} {curElement.city} {curElement.pincode} {curElement.state} {curElement.country}</p>
                                    </div>
                                </div>
                             </Link>
                            </div>
                        )) : ''}
                    </div>
                </div>
            </section>
        </>
    )
}
export default Projects;