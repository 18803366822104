
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import React, { useState, useEffect, } from 'react';
import PropertyApi from "../api/PropertyApi";


function Properties() {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState();
  const [locationData, setLocationData] = useState();
  const [keywordData, setKeywordData] = useState();
  const [selectedType, setSelectedType] = useState();
  const [price, setPrice] = useState(0);



  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch data from the API
  const fetchData = async () => {

    let properties = await PropertyApi.fetchPropertiesByTenantCode("ibs_laxmi");

    if (properties && properties?.length > 0) {
 
      let propertiesArray = [];
      let propertiesTypesArray = [];
      properties.forEach((element) => {
        if (element?.files) {
          let files = `[${element.files.replaceAll("@#", ",")}]`;
          // console.log('files :- ',files);
          element.files = JSON.parse(files);
        }
        propertiesArray.push(element);
        propertiesTypesArray.push({ label: element.type, value: element.type });

      });

      if(location.hasOwnProperty('pathname') && location.pathname.split('/').length >= 3){
        let arry = []
        let searchName =  decodeURIComponent(location.pathname.split('/')[2].split('=')[1]);
        console.log('searchName',decodeURIComponent(searchName));
        for(let data of propertiesArray){
          if(data.name.toLowerCase().includes(searchName.toLowerCase())  
          || data.type.toLowerCase().includes(searchName.toLowerCase()) 
          || data.city.toLowerCase().includes(searchName.toLowerCase()) 
          || data.state.toLowerCase().includes(searchName.toLowerCase())){
            arry.push(data);
          }
        }
        setFilteredOptions(arry);
      }else{
        setFilteredOptions(propertiesArray);
      }
      setItems(propertiesArray);
      setTypes(propertiesTypesArray);
    } else {
      setItems([]);
    }
  };


  // This code use of keyowrd Searching Start here
  const keywordInputChange = (event) => {
    const query = event.target.value;
    setKeywordData(query);
  };

  // this code use of property type searching start here
  const typeHandleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedType(selectedValue);
  };

  // this code use of property location searching start here
  const locationInputChange = (event) => {
    if (event.target.value === "") {
      setFilteredOptions(items);
    } else {
      setLocationData(event.target.value);
    }
  };

  // this code use of property Price searching start here
  const priceHandleInput = (e) => {
    setPrice(e.target.value);
  }

  // this code use of property search button start here
  const searchButtonhandleFilter = () => {
    const filtered = items.filter((option) => {
      let con = `${option.street} ${option.city} ${option.pincode} ${option.state} ${option.country}`
      if ((locationData && con.toLowerCase().includes(locationData.toLowerCase())) ||
        (keywordData && option.name.toLowerCase().includes(keywordData.toLowerCase())) ||
        (selectedType && option.type.toLowerCase() == selectedType.toLowerCase()) || (price && option.cost <= price)) {
        return option;
      }
    }
    );
    if (filtered.length > 0) {
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(items);
    }

  };


  // this code use of Format Currency start here
  function formatCurrency(number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'INR',
    }).format(number);
  }

  // this code use of get Max Price in  property cost start here
  let maxValue = items.reduce((acc, value) => {
    return (acc = acc > value.cost ? acc : value.cost);
  }, 0);


  return (
    <>
      <section id="properties" className="section-bg">
        <div className="container">
          <div className="col-lg-12 p-3">
            <h3 className="montserrat-semibold">Properties</h3>
          </div>
          <div className="row p-2">
            <div className="col-lg-4">
              <div className="card p-3 mb-3">
                <div className="card-body">
                  <h5 className="card-title montserrat-regular">Advanced Search</h5>
                  <form className="pt-3">
                    <div class="form-group mb-4">
                      <input type="text" placeholder="keyword" name="keyword" onChange={keywordInputChange} className="form-control p-2" />
                    </div>

                    <div className="form-group mb-3">
                      <input type="text" placeholder="Location" name="location"
                        onChange={locationInputChange} className="form-control p-2" />
                    </div>

                    <select onChange={typeHandleChange} className="form-select mb-4 p-2" aria-label="">
                      <option value="">Select Type</option>
                      {types ? types.map((curElement) => (
                        <option key={curElement.value} value={curElement.value}>
                          {curElement.label}
                        </option>
                      )) : ''}
                    </select>

                    <div className="form-group mb-3">
                      <input id="pi_input1" type="range" max={maxValue} step="1" onChange={priceHandleInput} style={{ width: '100%' }} />
                      <h5>Price: {formatCurrency(price)}</h5>
                    </div>

                    <div class="d-grid gap-2">
                      <button class="btn large-btn p-2" type="button" onClick={searchButtonhandleFilter}>Find Property</button>
                    </div>

                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              {filteredOptions ? filteredOptions.map((curElement) => (
                <div className="card mb-3 p-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-4 pb-1">
                        <div className="img-hover-zoom img-hover-zoom-quick-zoom">
                       
                        {curElement?.files?.length > 0 ? 
                          <img src={`https://property.indicrm.io/images/ibs_laxmi/${curElement.id}/${curElement.files[0].Id}.${curElement.files[0].fileType}`} class="img-fluid h-100" width={'100%'} alt='proparty img' /> : 
                          <img src="/no-img-found.svg" class="card-img-top w-100 img-fluid" alt="noImage" />}
                        </div>
                      </div>

                      <div className="col-lg-8 pb-1">
                        <div className='row'>
                          <div className='col-lg-8'>
                            <Link to={`/property-details/${curElement.id}`} state={curElement}>
                              <button type='button' className='btn small-btn'>Details</button>
                            </Link>

                          </div>
                          <div className='col-lg-4'>
                            <h5 className='property-price montserrat-regular'>{formatCurrency(curElement.cost)}</h5>
                          </div>
                        </div>
                        <div className="pt-3">
                          <p className="mb-0">{curElement.type}</p>
                          <h5 className='montserrat-semibold'>{curElement.name}</h5>
                          <p className='pt-2 mb-3 montserrat-regular'><FaMapMarkerAlt color='#5FAF8A' />{curElement.street} {curElement.city} {curElement.pincode} {curElement.state} {curElement.country}</p>
                        </div>

                        <div className='col-lg-12 text-break'>
                          <ul className='pl-0 montserrat-regular'>
                            <li>Area: {curElement.area}</li>
                            <li>Areameasure: {curElement.areameasure}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )) : ''}

            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Properties;

