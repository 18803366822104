/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/no-redundant-roles */
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

function Contact() {
  return (
    <>
      <section id="Contact">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h3><span>Contact Us</span></h3>
          </div>


          <div className="row" data-aos="fade-up" data-aos-delay="100">
            <div className="col-lg-6 ">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30276.762856665657!2d73.79165851685248!3d18.456672670282085!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2953ac44c7ddf%3A0x737faa6b90930b51!2sAmbegaon%20Budruk%2C%20Pune%2C%20Maharashtra%20411046!5e0!3m2!1sen!2sin!4v1692882973772!5m2!1sen!2sin" frameborder="0" style={{ border: '0', width: '100%', height: '320px' }} allowfullscreen></iframe>
            </div>

            <div className="col-lg-6">
              <div className="row" data-aos="fade-up" data-aos-delay="100">
                <div className="col-lg-12">
                  <div className="info-box mb-4">
                    <FaMapMarkerAlt size={28} className="theme-color1" />
                    <h3>Our Address</h3>
                    <p>Sr. No. 15, Ambegaon Budruk,Pune-411046,
                      Maharashtra,INDIA.</p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="info-box  mb-4">
                    <FaEnvelope size={28} className="theme-color1" />
                    <h3>Email Us</h3>
                    <p>shilpi.b@ibirdsservices.com</p>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6">
                  <div className="info-box  mb-4">
                    <FaPhoneAlt size={28} className="theme-color1" />
                    <h3>Call Us</h3>
                    <p>+91 94140 07736</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Contact;