import "maphilight";
import $ from 'jquery';

const ProjectProperty2 = () => {

    $(function () {
        $('.maparea').maphilight({
            stroke: false,
            fillColor: '85B061',
            fillOpacity: '0.5'
        });
    });
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 text-start">
                        <div className="pt-4 ps-4">
                            <h3 className="pb-4">Property Details</h3>
                            <div className="">
                                <h6 className="montserrat-bold">Name</h6>
                                <h6>Commerce Center Mall</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-bold">Location</h6>
                                <h6>Mansingh Palace Hotel, Apna Nagar, Shantipura, Ajmer, Rajasthan 305001</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-semibold">Owner Contact</h6>
                                <h6>+91 93454-34454</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-bold">Description</h6>
                                <h6>Lorem Ipsum is simply dummy text of the printing
                                    and typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic typesetting,
                                    remaining essentially unchanged.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 m-0 p-0">
                        {/* <img id="Image-Maps-Com-image-maps-2023-08-18-130634"  className="maparea" src="https://app.image-maps.com/m/private/0/6ib2m7l4efg92boa4gq7vcigdm_bindling-image2.jpg" border="0" width="1139" height="1200" orgWidth="1139" orgHeight="1200" usemap="#image-maps-2023-08-18-130634" alt="" />
                        <map name="image-maps-2023-08-18-130634" id="ImageMapsCom-image-maps-2023-08-18-130634">
                            <area shape="rect" coords="1137,1198,1139,1200" alt="Image Map" style={{ outline: 'none' }} title="Image Map" href="https://www.image-maps.com/" />
                            <area alt="" title="" href="/appartment_images/appartment1.jpg" shape="poly" coords="355,400,431,423,504,418,509,498,442,501,355,471" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment2.jpg" shape="poly" coords="507,574,511,499,435,501,361,472,363,547,437,580" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment3.jpg" shape="poly" coords="506,653,511,579,439,582,365,547,362,619,436,657" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment4.jpg" shape="poly" coords="508,730,509,654,444,658,366,617,367,692,437,735" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment5.jpg" shape="poly" coords="508,800,512,728,438,736,366,692,369,764,443,811" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment6.jpg" shape="poly" coords="599,524,684,553,749,545,753,615,688,625,601,589" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment7.jpg" shape="poly" coords="749,756,753,687,693,694,601,655,602,729,689,768" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment8.jpg" shape="poly" coords="750,687,749,615,690,624,603,590,604,656,687,694" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment9.jpg" shape="poly" coords="747,826,751,759,696,766,607,727,606,798,687,837" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment10.jpg" shape="poly" coords="782,483,914,520,965,519,970,586,916,592,785,552,778,551" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment1.jpg" shape="poly" coords="964,652,964,585,919,589,786,552,783,610,905,658" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="" href="/appartment_images/appartment2.jpg" shape="poly" coords="966,720,964,654,912,658,813,625,809,688,906,727" style={{ outline: 'none' }} target="_blank" />
                        </map> */}

                        <img className="maparea float-end" id="Image-Maps-Com-image-maps-2023-08-22-101303" src="https://app.image-maps.com/m/private/0/lf0u0dh8qnk20to93tlpkajsjg_project2.jpg" border="0" width="1000" height="900" orgWidth="1000" orgHeight="900" usemap="#image-maps-2023-08-22-101303" alt="" />
                        <map name="image-maps-2023-08-22-101303" id="ImageMapsCom-image-maps-2023-08-22-101303">
                            {/* <area shape="rect" coords="998,898,1000,900" alt="Image Map" style={{ outline: 'none' }} title="Image Map" href="https://www.image-maps.com/" /> */}
                            <area alt="" title="Appartment1" href="/appartment_images/appartment1.jpg" shape="poly" coords="343,294,423,319,498,314,498,378,425,385,347,357" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment2" href="/appartment_images/appartment2.jpg" shape="poly" coords="497,444,499,375,426,381,352,355,352,420,426,450" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment3" href="/appartment_images/appartment3.jpg" shape="poly" coords="498,509,498,441,427,448,351,420,353,479,427,514" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment4" href="/appartment_images/appartment4.jpg" shape="poly" coords="504,570,502,507,432,513,357,479,357,543,432,578" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment5" href="/appartment_images/appartment5.jpg" shape="poly" coords="503,636,501,570,432,578,360,543,360,602,432,642" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment6" href="/appartment_images/appartment6.jpg" shape="poly" coords="506,691,502,633,433,642,365,607,361,663,434,703" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment7" href="/appartment_images/appartment7.jpg" shape="poly" coords="597,402.00000762939453,681,425.00000762939453,749,417.00000762939453,749,479.00000762939453,686,486.00000762939453,599,454.00000762939453" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment8" href="/appartment_images/appartment8.jpg" shape="poly" coords="748,537.0000076293945,746,479.00000762939453,688,482.00000762939453,600,454.00000762939453,598,511.00000762939453,684,546.0000076293945" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment9" href="/appartment_images/appartment9.jpg" shape="poly" coords="748,597.0000076293945,746,538.0000076293945,685,543.0000076293945,599,513.0000076293945,600,571.0000076293945,684,607.0000076293945" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment9" href="/appartment_images/appartment10.jpg" shape="poly" coords="747,656,750,598,688,606,602,570,605,627,684,663" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment10" href="/appartment_images/appartment1.jpg" shape="poly" coords="53,370,135,410,214,402,216,474,137,482,56,434" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment11" href="/appartment_images/appartment2.jpg" shape="poly" coords="215,402,211,332,131,339,50,306,56,374,134,410" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment12" href="/appartment_images/appartment3.jpg" shape="poly" coords="221,542,219,473,138,479,57,432,63,499,144,551" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment13" href="/appartment_images/appartment4.jpg" shape="poly" coords="223,611,222,537,142,549,66,498,65,559,145,620" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment14" href="/appartment_images/appartment5.jpg" shape="poly" coords="228,674,224,608,145,617,68,562,78,635,149,683" style={{ outline: 'none' }} target="_blank" />
                        </map>
                    </div>

                </div>
            </div>
        </>
    );
}
export default ProjectProperty2;
