import "maphilight";
import $ from 'jquery';
import { useLocation } from 'react-router-dom';


const ProjectProperty1 = () => {

    const { state } = useLocation();

    $(function () {
        $('.maparea').maphilight({
            stroke: false,
            fillColor: '85B061',
            fillOpacity: '0.5'
        });
    });

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 text-start">
                        <div className="pt-4 ps-4">
                            <h3 className="pb-4">Project Details</h3>
                            <div className="">
                                <h6 className="montserrat-bold">Name</h6>
                                <h6>{state.name}</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-bold">Location</h6>
                                <h6>{state.street} {state.city} {state.pincode} {state.state} {state.country}</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-semibold">Owner Name</h6>
                                <h6>{state.contactname}</h6>
                            </div><hr />

                            <div className="">
                                <h6 className="montserrat-bold">Description</h6>
                                <h6>Lorem Ipsum is simply dummy text of the printing
                                    and typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic typesetting,
                                    remaining essentially unchanged.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 m-0 p-0">
                        <img className="maparea float-end" id="Image-Maps-Com-image-maps-2023-08-22-094056" src="/project-img1.png" border="0" width="1000" height="900" orgWidth="1000" orgHeight="900" usemap="#image-maps-2023-08-22-094056" alt="" />
                        <map name="image-maps-2023-08-22-094056" id="ImageMapsCom-image-maps-2023-08-22-094056">
                            {/* <area shape="rect" coords="998,898,1000,900" alt="Image Map" style={{ outline: 'none' }} title="Image Map" href="https://www.image-maps.com/" /> */}
                            <area alt="" title="Appartment1" href="/appartment_images/appartment1.jpg" shape="poly" coords="669,137,556,107,285,148,285,180,557,147,669,173" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment2" href="/appartment_images/appartment2.jpg" shape="poly" coords="669,207,669,170,556,146,287,180,285,212,557,184" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment3" href="/appartment_images/appartment3.jpg" shape="poly" coords="668,240,556,224,287,242,285,210,556,184,668,204" style={{ outline: 'none' }} target="_blank" onmouseover="if(document.images) document.getElementById('Image-Maps-Com-image-maps-2023-08-22-094056').src= '';" onmouseout="if(document.images) document.getElementById('Image-Maps-Com-image-maps-2023-08-22-094056').src= 'https://app.image-maps.com/m/private/0/lf0u0dh8qnk20to93tlpkajsjg_project1.png';" />
                            <area alt="" title="Appartment4" href="/appartment_images/appartment4.jpg" shape="poly" coords="669,272.00000762939453,667,239.00000762939453,556,224.00000762939453,288,242.00000762939453,286,274.00000762939453,556,263.00000762939453" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment5" href="/appartment_images/appartment5.jpg" shape="poly" coords="669,307.00000762939453,668,273.00000762939453,555,263.00000762939453,286,273.00000762939453,283,303.00000762939453,556,301.00000762939453" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment6" href="/appartment_images/appartment6.jpg" shape="poly" coords="668,341.00000762939453,668,306.00000762939453,556,303.00000762939453,285,303.00000762939453,284,336.00000762939453" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment7" href="/appartment_images/appartment7.jpg" shape="poly" coords="666,375,668,338,556,342,284,336,284,366,556,384" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment8" href="/appartment_images/appartment8.jpg" shape="poly" coords="668,409.00001525878906,669,371.00001525878906,557,380.00001525878906,285,365.00001525878906,284,399.00001525878906,558,421.00001525878906" style={{ outline: 'none' }} target="_blank" />
                            <area alt="" title="Appartment9" href="/appartment_images/appartment9.jpg" shape="poly" coords="670,444.00001525878906,670,406.00001525878906,556,422.00001525878906,285,397.00001525878906,284,430.00001525878906,558,460.00001525878906" style={{ outline: 'none' }} target="_blank" />
                        </map>
                    </div>

                </div>
            </div>
        </>
    );
}
export default ProjectProperty1;
