import * as constants from '../constants/CONSTANT';

const PropertyApi = {
    async fetchPropertiesByTenantCode(tenantCode) {
        let response = await fetch(constants.API_BASE_URL + `/api/properties/public/${tenantCode}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': token
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }

        });
        const result = await response.json();
        return result;
    },

    async fetchProjectByTenantCode(tenantCode) {
        let response = await fetch(constants.API_BASE_URL + `/api/projects/public/${tenantCode}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': token
                // 'Content-Type': 'application/x-www-form-urlencoded',
            }

        });
        const result = await response.json();
        return result;
    },
    async interestedPropertyCreateLead(body, tenantCode) {
        console.log("interestedPropertyCreateLead " , body, tenantCode)
        let response = await fetch(constants.API_BASE_URL + `/api/leads/interested/propertylead/${tenantCode}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': token
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body : JSON.stringify(body)

        });
        const result = await response.json();
        console.log("result " , result)
        return result;
    },
}
export default PropertyApi;