import './App.css';
import './assets/css/style.css';
import { Routes, Route, } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Properties from './Components/Properties';
import PropertyDetails from './Components/propertyDetails';
import Projects from './Components/Projects';
import ProjectProperty1 from './Components/projects_pages/projectProperty1';
import ProjectProperty2 from './Components/projects_pages/projectProperty2';
import ScrollButton from './Components/ScrollButton';
import PubSub from 'pubsub-js';
import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from "react-bootstrap";

function App() {

  const [modalShow, setModalShow] = useState(false);
  const [title, setTitle] = useState('Confirmation');
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState('');
  const mySubscriber = (msg, data) => {

    switch (msg) {
      case 'RECORD_SAVED_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('success')
        //console.log('mytopic: RECORD_SAVED_TOAST'); // add your desired log message
        break;
      case 'RECORD_ERROR_TOAST':
        setTitle(data.title);
        setMessage(data.message);
        setModalShow(true);
        setVariant('danger')
        //console.log('mytopic: RECORD_ERROR_TOAST'); // add your desired log message
        break;
      default:
        //console.log('mytopic: default'); // add your desired log message  
        break;
    }
  };
  useEffect(() => {
    PubSub.subscribe('RECORD_SAVED_TOAST', mySubscriber);
    PubSub.subscribe('RECORD_ERROR_TOAST', mySubscriber);
  }, []);
  return (
    <>
      <ToastContainer className="p-3" position='top-center'>
        <Toast show={modalShow} onClose={() => setModalShow(false)} delay={3000} bg={variant} className="text-white" autohide>
          {variant === 'success' ?
            <div className="p-1 m-1" style={{ backgroundColor: '#198754', color: 'white' }} >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div> :
            <div className="p-1 m-1" style={{ backgroundColor: '#DC3545', color: 'white' }} >
              <i className="fa-regular fa-circle-check text-white mx-2"></i>
              <strong className="me-auto">{title}</strong>
              <i className="fa-solid fa-xmark text-white float-right" style={{ float: 'right' }} role='button' onClick={() => setModalShow(false)}></i>
            </div>}
          <Toast.Body>
            {message instanceof Array ? message.map((item) => { return <span>{item.msg}</span> }) : message instanceof Object ? <span>{message.detail}</span> : <span>{message}</span>}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/properties/:search" element={<Properties />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/property-details/:id" element={<PropertyDetails />} />
          <Route path="/projectProperty1" element={<ProjectProperty1 />} />
          <Route path="/projectProperty2" element={<ProjectProperty2 />} />
        </Routes>
        <ScrollButton />
        <Footer />

      </div>
    </>
  )
}

export default App;
