import aboutImg from '../assets/img/about-img2.jpg';
import { BiBuildingHouse, BiGlobe, BiDonateHeart } from "react-icons/bi";


function About() {
  return (
    <>

      <section id="About" className="" style={{ paddingBottom: '0px' }}>
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h3>About Us</h3>
          </div>
          <div className="row pb-3">
            <div className="col-lg-5 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="100">
              <div className="about-details">
                <h2 className='montserrat-semibold'>Lakshmi Developers</h2>
                <p className="pt-2 text-justify">
                  Lakshmi Developers is a disruptive real-estate platform that makes it possible
                  to buy/sell/rent a house without paying any brokerage.

                  Lakshmi Developers
                  was started because all of us believed that paying hefty brokerage could not be the only
                  option to find a new home. As tenants, we have been paying these brokerages year after year
                  without seeing any advantage of the broker. The only reason it existed was that there was a huge
                  information asymmetry in the market. Lakshmi Developers is a platform that removes this information
                  asymmetry and provides a marketplace for free exchange of this information that used to cost 1-2 months
                  of rent as brokerage.
                </p>
              </div>
            </div>
            <div className="col-lg-7 text-center" data-aos="fade-right" data-aos-delay="100">
              <img src={aboutImg} className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-3 common-bg-img">
          <div className='row pt-5 pb-5 d-flex justify-content-center'>
            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
                <div className='icon'><BiBuildingHouse /></div>
                <div class="card-body">
                  <h5 class="montserrat-semibold card-title">Our Mission</h5>
                  <p class="card-text">To provide superior full-service real estate brokerage with in-depth market knowledge and ethical practices.</p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
                <div className='icon'><BiGlobe /></div>
                <div class="card-body">
                  <h5 class="montserrat-semibold card-title">Our Vission</h5>
                  <p class="card-text">Be recognized as the leading agency in property services as wall as a reliable
                    source of market information and advice.</p>
                </div>
              </div>
            </div>

            <div className='col-lg-3 mb-2'>
              <div class="card border-0 p-3 h-100">
                <div className='icon'><BiDonateHeart /></div>
                <div class="card-body">
                  <h5 class="montserrat-semibold card-title">Our value</h5>
                  <p class="card-text">Building long-term relationships with clients,staying as loyal to our employee as they are to us,constant growth and development.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About;